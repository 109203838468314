import React from "react";
import "./HelpPopup.css";

const HelpPopup = ({ onClose }) => {
  return (
    <div className="help-popup">
      <div className="help-content">
        <h2>Updates</h2>


     <p className="space-below">
        <h4>🌞 Fixed youtube/soundcloud converter url parser</h4>
        <h4>🌞 Mood Switcher</h4>
          </p>
          

        <p className="space-below">
        <h4>🎵 Transcript from audio, youtube/soundcloud converter *Up to 30 minutes file </h4>
          Just paste youtube/soundcloud url and you'll get the AUDIO in mp3 plus TRANSCRIPT. <br />      
          Add "mp3" right after the url and you'll get only the mp3.<br />
          Example: Only the mp3: <i>https://youtu.be/tT4d1LQy4es mp3</i><br />
          </p>
          
          <p className="space-below">
        <h4>🗣️ She can talk</h4>
          Enable the speech icon - 🗣️ and chat with Sonya. <br /> 
          Ask her something. <br /> 
          Enjoy her natural voice.  <br />      
          </p>

          <p className="space-below">
        <h4>🎨 She can draw</h4>
          Ask her to show you a picture of something.<br /> 
          Example: <i>display a picture of a white siamese cat</i>  <br />      
          </p>

          <p className="space-below">
        <h4>🎙️ You can talk to her</h4>
          Enable the listen microphone icon - 🎙️ and talk.  <br />
          Your speech to text in real time. Use headphones ;)  <br />      
          </p>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default HelpPopup;

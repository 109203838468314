export function youtube_parser(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&\s]+?)(?=[&?][^#]*|\s+mp3|$)/;
  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return "https://www.youtube.com/watch?v=" + match[2].replace(/\s+mp3$/, ''); // Remove the "mp3" keyword from the URL before returning it
  } else {
    return null;
  }
}





import React, { useState } from "react";
import './App.css';

const MoodSwitcher = ({ currentMood }) => {
  const [mood, setMood] = useState(currentMood || 'neutral');

  const handleMoodChange = (event) => {
    const newMood = event.target.value;
    setMood(newMood);

    changeMoodOnServer(newMood); // Call the changeMoodOnServer function with the new mood
  };

  const changeMoodOnServer = async (newMood) => {
    try {
    //const response = await fetch('http://localhost:3001/api/change-mood', {
    const response = await fetch('/api/change-mood', {  
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ mood: newMood }) // Send the new mood in the body of the request
      });
      const data = await response.json(); // This line assumes your server responds with JSON
      console.log(data.message); // Log the server's response
    } catch (error) {
      console.error('Error:', error);
    }
  };

return (
    <div className="mood-switcher">
      <select value={mood} onChange={handleMoodChange}>
        <option value="neutral">👽 Based</option>
        <option value="happy">😀 Happy</option>
        <option value="sad">😔 Sad</option>
        <option value="angry">😡 Angry</option>
        <option value="excited">🤩 Excited</option>
        <option value="relaxed">😌 Relaxed</option>
      </select>
    </div>
  );
};

export default MoodSwitcher;


import React, { useEffect, useRef } from "react";

const Waveform = ({ listening }) => {
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);

  useEffect(() => {
    if (listening) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          audioContextRef.current = new AudioContext();
          analyserRef.current = audioContextRef.current.createAnalyser();
          analyserRef.current.fftSize = 2048;

          const source = audioContextRef.current.createMediaStreamSource(stream);
          source.connect(analyserRef.current);

          const bufferLength = analyserRef.current.frequencyBinCount;
          const dataArray = new Uint8Array(bufferLength);
          const canvas = canvasRef.current;
          const canvasCtx = canvas.getContext("2d");

          canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

          function drawWaveform() {
            animationFrameRef.current = requestAnimationFrame(drawWaveform);

            analyserRef.current.getByteTimeDomainData(dataArray);

            canvasCtx.fillStyle = "#212121";
            canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = "rgb(0, 255, 0)";
            canvasCtx.beginPath();

            const sliceWidth = (canvas.width * 1.0) / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
              const v = dataArray[i] / 128.0;
              const y = v * canvas.height / 2;

              if (i === 0) {
                canvasCtx.moveTo(x, y);
              } else {
                canvasCtx.lineTo(x, y);
              }

              x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
          }

          drawWaveform();
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
        });
    } else {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [listening]);

  return <canvas ref={canvasRef} width="100" height="30"></canvas>;
};

export default Waveform;


import React, { useState, useEffect, useRef, useCallback } from "react";
import Waveform from "./Waveform";
import "./App.css";
import "./HelpPopup.css";
import { youtube_parser } from "./helpers";
import { saveAs } from 'file-saver';
import HelpPopup from "./HelpPopup";
import MoodSwitcher from './MoodSwitcher';

function checkForImageRequest(text) {
  const imageKeywords = [
    "can you (show|draw) (me )?a (picture|drawing|image|sketch) of",
    "show me a (picture|drawing|image|sketch) of",
    "draw me a (picture|drawing|image|sketch) of",
    "i want to see a (picture|drawing|image|sketch) of",
    "i'd like to see a (picture|drawing|image|sketch) of",
    "i'm looking for a (picture|drawing|image|sketch) of",
    "let me see a (picture|drawing|image|sketch) of",
    "display a (picture|drawing|image|sketch) of",
  ];

  const regex = new RegExp(imageKeywords.join("|"), "i");
  const match = text.match(regex);

  if (match) {
    return text.replace(match[0], "").trim();
  }

  return null;
}

async function sendMessage(messageToSend, endpoint = "/api") {
  const res = await fetch(`${endpoint}`, {
 // const res = await fetch(`http://localhost:3001${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ message: messageToSend }),
  });
  return await res.json();
}

function App() {
  const [listening, setListening] = useState(false);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const chatHistoryRef = useRef(null);
  const messageRef = useRef(null);
  const recognitionRef = useRef(null);
  const [speakEnabled, setSpeakEnabled] = useState(false);
  const chatHistoryLimit = 10;
const handleMoodChange = useCallback((mood) => {
//fetch('http://localhost:3001/api/change-mood', {
  fetch('https://sonyacassie.com:3001/api/change-mood', {    
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ mood: mood }),
  })
    .then(response => response.json())
    .then(data => {
      console.log('Mood successfully changed:', data);
      // Here you can update your state or do anything else with the response...
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}, []);
  
  const [showHelp, setShowHelp] = useState(false); // the popup
  const toggleHelp = () => {
    setShowHelp(!showHelp);
  };

  const [conversionProgress, setConversionProgress] = useState(false);

  const handleSendMessage = useCallback((text) => {
    if (chatHistory.find((item) => item.message === text)) {
      return;
    }
  
// SoundCloud URL parser
function soundcloud_parser(url) {
const soundcloudPattern = /^https?:\/\/(www\.)?soundcloud\.com\/[\w\d-]+(\/[\w\d-]+)*\/?(?:\?[^#]*)?(\s+mp3)?$/;
  if (soundcloudPattern.test(url)) {
    return url.replace(/\s+mp3$/, ''); // Remove the "mp3" keyword from the URL before returning it
  }
  return false;
}

// Conversion function for YouTube and SoundCloud
async function convertInputUrl(input_url, transcribe) {
  setConversionProgress(true); // Start progress indicator
  try {
    //const response = await fetch("http://localhost:5000/api/convert_youtube", {
    const response = await fetch("https://sonyacassie.com:5000/api/convert_youtube", {  
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({ input_url, transcribe }),
      });

      const data = await response.json();

      if (data.error) {
        alert(data.error);
    } else {
        const fileUrl = data.download_url;
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        saveAs(blob, data.filename);
    
        // Add the transcription to the chat history, only if it's available
        if (data.transcription) {
            const newChatHistory = [
                ...chatHistory,
                { message: "Transcription:", response: data.transcription },
            ];
            if (newChatHistory.length > chatHistoryLimit) {
                newChatHistory.splice(0, newChatHistory.length - chatHistoryLimit);
            }
            setChatHistory(newChatHistory);
            setTimeout(() => {
                chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
            }, 100);
        }
    }
    
  } catch (error) {
      console.error("Error processing the request: ", error);
      alert("An error occurred while processing the request.");
  } finally {
      setConversionProgress(false);
  }
}

const youtubeUrl = youtube_parser(text);
const soundcloudUrl = soundcloud_parser(text);
const transcribe = !text.toLowerCase().includes("mp3");  // If "mp3" is in the message, set transcribe to False

if (youtubeUrl) {
  convertInputUrl(youtubeUrl, transcribe);
  return;
} else if (soundcloudUrl) {
  convertInputUrl(soundcloudUrl, transcribe);
  return;
} 

// Dalle integration
  const imagePrompt = checkForImageRequest(text);

// Check if the message starts with "imagine"
if (imagePrompt !== null) {
  const imagePrompt = text.replace("imagine", "").trim();
  sendMessage(imagePrompt, "/api/create-image").then((data) => {
    const newChatHistory = [      ...chatHistory,      { message: text, response: "", image_url: data.image_url },    ];
    if (newChatHistory.length > chatHistoryLimit) {
      newChatHistory.splice(0, newChatHistory.length - chatHistoryLimit);
    }
    setChatHistory(newChatHistory);
    setMessage("");

    // Scroll to the bottom of the chat history after adding the new image message
    setTimeout(() => {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }, 300);
  });
} else {
  sendMessage(text).then((data) => {
    const newChatHistory = [      ...chatHistory,      { message: text, response: data.message },    ];
    if (newChatHistory.length > chatHistoryLimit) {
      newChatHistory.splice(0, newChatHistory.length - chatHistoryLimit);
    }
    setChatHistory(newChatHistory);
    setTimeout(() => {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }, 100);
    setMessage("");

    if (speakEnabled) {
      sendMessage("");
    }
    if (listening) {
      setTimeout(() => {
        recognitionRef.current.start();
      }, 3000); // wait 3 seconds before starting recognition again
    }
    if (speakEnabled) { //condition check
    // call Eleven Labs API to get audio stream for chatbot response - fuck
    const requestBody = {
      text: data.message,
      model_id: "eleven_multilingual_v2",
      voice_settings: {
        stability: 0.35,
        similarity_boost: 0.75,
      },
    };
    fetch(
      "https://api.elevenlabs.io/v1/text-to-speech/ZTiVxD0KCWHnV4ICV9kN/stream",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "xi-api-key": "97833f6a69b0c11e197c072e26430359",
        },
        body: JSON.stringify(requestBody),
      }
    )
      .then((res) => {
        const audioStream = res.body;
        const reader = audioStream.getReader();
        let chunks = [];
        return reader.read().then(function processResult(result) {
          if (result.done) {
            const audioBlob = new Blob(chunks, { type: "audio/mpeg" });
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            audio.play();

            const newChatHistory = [              ...chatHistory,              { message: text, response: data.message },            ];
            if (newChatHistory.length > chatHistoryLimit) {
              newChatHistory.splice(0, newChatHistory.length - chatHistoryLimit);
            }
            setChatHistory(newChatHistory);
            setTimeout(() => {
              chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
            }, 100);

            if (listening) {
              setTimeout(() => {
                recognitionRef.current.start();
              }, 3000); // wait 3 seconds before starting recognition again
            }
          } else {
            chunks.push(result.value);
            return reader.read().then(processResult);
          }
        });
      })
      .catch((error) => console.log(error));
    }
  });
}
}, [chatHistory, chatHistoryLimit, listening, speakEnabled]);
useEffect(() => {
  if (!("webkitSpeechRecognition" in window)) {
    console.log("Speech recognition not supported");
    return;
  }
  recognitionRef.current = new window.webkitSpeechRecognition();
  recognitionRef.current.continuous = true;
  recognitionRef.current.interimResults = true;

  recognitionRef.current.onresult = (event) => {
    let interimTranscript = "";
    for (let i = event.resultIndex; i < event.results.length; i++) {
      const transcript = event.results[i][0].transcript;
      interimTranscript += transcript;
      if (event.results[i].isFinal) {
        setMessage(interimTranscript);
        handleSendMessage(interimTranscript);
        break;
      }
    }
  };
}, [handleSendMessage]);

  const handleSpeakEnabled = () => {
    setSpeakEnabled(!speakEnabled);
  };

  const handleListen = (e) => {
    e.preventDefault();
    if (!listening) {
      setListening(true);
      recognitionRef.current.start(); // start the recognition
    } else {
      setListening(false);
      recognitionRef.current.stop(); // stop the recognition
    }
  };
    const handleSubmit = (e) => {
    e.preventDefault();
    if (!message) {
      return;
    }

    handleSendMessage(message);
    // "you" message to be send first
    setChatHistory([...chatHistory, { message: message }]);
    setMessage("");
  };

  return (
    <div className="app">
      <div className="help-icon" onClick={toggleHelp}>
    <span>?</span>
  </div>
  {showHelp && <HelpPopup onClose={toggleHelp} />}
  <MoodSwitcher onMoodChange={handleMoodChange} />
      <div className="chat-history" ref={chatHistoryRef}>
        {chatHistory.map((item) => (
          <div
            className="chat-bubble"
            key={item.message + item.response + Math.random()}
            ref={(el) => (messageRef.current = el)}
          >
            <div className="you">
              <span></span>
              <span className="you-message">{item.message}</span>
            </div>
            <div className="cassie">
              <span></span>
              <span className="cassie-message">{item.response}</span>
              {item.image_url && (
                <img
                  className="cassie-image"
                  src={item.image_url}
                  alt="Generated from user input"
                />
              )}
            </div>
          </div>
        ))}
{conversionProgress && (
  <div className="conversion-progress">
    getting the audio... <span className="bounceloading">🤔</span>
  </div>
)}

 </div>
      <form
        onSubmit={(e) => handleSubmit(e, message)}
        className="chat-form"
        onKeyDown={(e) => e.key === "Enter" && handleSubmit(e, message)}
      >
        <input
          className="chat-input"
          type="text"
          value={message}
          placeholder="Type your message here..."
          onChange={(e) => setMessage(e.target.value)}
        />

        <label className="speak-icon-container" onClick={handleSpeakEnabled}>
          <span>
            {speakEnabled ? (
              <span className="speak-icon-on">🗣️</span>
            ) : (
              <span className="speak-icon-off">🗣️</span>
            )}
          </span>
          <span className="tooltip">Let's talk 💀</span>
        </label>
        <div className="microphone-container">
  {listening && <Waveform listening={listening} />}
  <button
    type="button"
    className={`mikrofon ${listening ? "active" : ""}`}
    onClick={(e) => handleListen(e)}
  >
    <span className="icon">🎙️</span>
  </button>
</div>
        <button className="submit-button" type="submit">
          <span className="bounce">🤔</span>
        </button>
      </form>
    </div>
  );

}
export default App;

